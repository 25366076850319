import mitt from 'mitt';
import Tracking from './tracking';
import DatalakeTracker from './datalake-tracker';

const eventBus = mitt();

const tracking = new Tracking();
const datalakeTracker = new DatalakeTracker();

export default {
	eventBus,
	tracking,
	datalakeTracker,
};
