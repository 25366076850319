/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import ITrackingObject from '../interfaces/tracking-object.interface';
import categories from '../dictionary/tracking/categories';
import actions from '../dictionary/tracking/actions';

export default class Tracking {
	public actions = actions;
	public categories = categories;

	/**
	 * @deprecated Please use DatalakeTracker instead
	 */
	public snowPlow(trackingParams: ITrackingObject): void | null {
		if (typeof window['snowplow'] === 'undefined') return null;

		window['snowplow']('trackStructEvent', {
			category: trackingParams.category,
			action: trackingParams.action,
			label: trackingParams.label || '',
			property: trackingParams.property || '',
			value: trackingParams.value || 0,
		});
	}
}
