import { IEvent } from '@studyportals/student-interfaces';
import { EventType } from '../enums/event-type';
import { IComparisonToolApplication } from '../';

export class ComparisonToolReadyEvent implements IEvent {
	public static readonly EventType: string =
		EventType.COMPARISON_TOOL_READY_EVENT;

	public readonly timestamp: Date;
	public readonly eventType: string = ComparisonToolReadyEvent.EventType;

	constructor(public comparisonToolApplication: IComparisonToolApplication) {
		this.timestamp = new Date();
	}
}
