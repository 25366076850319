import {
	EventNames,
	ImpressionValidator,
} from '@studyportals/impression-validator';
import globals from './globals';

export default class ImpressionTracker {
	private impressionValidator?: ImpressionValidator;

	constructor(
		private parentElement: HTMLElement | null,
		private studyId: number
	) {
		if (!this.parentElement) {
			console.warn('No parent element found');
			return;
		}

		this.bindImpressionEvent();

		this.impressionValidator = new ImpressionValidator(
			this.parentElement,
			'js-Compare-Widget'
		);
		this.impressionValidator.processElements();
	}

	public processElement(): void {
		this.impressionValidator?.processElements();
	}

	private bindImpressionEvent(): void {
		const element = this.parentElement?.querySelector('.js-Compare-Widget');
		element?.addEventListener(EventNames.VALIDATED_IMPRESSION, () => {
			globals.datalakeTracker.trackImpression(this.studyId);
		});
	}
}
