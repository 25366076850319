import { readonly } from 'vue';

import { IState, IStore } from '../interfaces/store.interfaces';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const store: IStore = {
	state: readonly(state) as IState,
	getters,
	mutations,
	actions,
};

export default store;
