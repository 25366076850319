import { IComparisonStudy } from '../interfaces/comparison-study.interface';
import { IGetters } from '../interfaces/store.interfaces';
import state from './state';

const getters: IGetters = {
	studies: (): IComparisonStudy[] => state.studies,
	studyIds: (): number[] => state.studyIds,
	initialized: (): boolean => state.initialized,
	error: (): boolean => state.error,
	canShowComparison: (): boolean => state.studyIds.length > 1,
};

export default getters;
