import { defineComponent } from 'vue';
import { setupComponent } from '@studyportals/vue-platform';
import widget from './widget.class';

export default defineComponent({
	props: {
		studyId: Number,
		widgetLocation: String,
	},
	setup: (props) => {
		const codeBehind = new widget(props.studyId as number);

		return setupComponent(codeBehind);
	},
});
