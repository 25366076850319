import { IComparisonStudy } from '../interfaces/comparison-study.interface';
import { IMutations } from '../interfaces/store.interfaces';
import state from './state';

const mutations: IMutations = {
	setStudies: (studies: IComparisonStudy[]): void => {
		state.studies = studies;
	},
	setInitializedFlag: (): void => {
		state.initialized = true;
	},
	unsetInitializedFlag: (): void => {
		state.initialized = false;
	},
	setErrorFlag: (): void => {
		state.error = true;
	},
	removeStudy: (studyId: number): void => {
		state.studies = state.studies.filter((s) => s.id !== studyId);
	},
	addStudyId: (study: number): void => {
		state.studyIds.push(study);
	},
	removeStudyId: (studyId: number): void => {
		state.studyIds = state.studyIds.filter((s) => s !== studyId);
	},
};

export default mutations;
