import { IComparisonStudy } from '../../interfaces/comparison-study.interface';

declare const API_BASE_URL: string;

export class StudyDataRepository {
	private apiURL: string;

	constructor() {
		try {
			this.apiURL = API_BASE_URL;
		} catch (e) {
			this.apiURL = '';
		}
	}

	public async get(studyIds: number[]): Promise<IComparisonStudy[]> {
		const url = this.apiURL + 'studies?ids=' + studyIds.join(',');
		let response: any;

		try {
			response = await fetch(url);
			/* 	eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,
				@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
			response = await response.json();
		} catch (error) {
			response = {
				studies: [],
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const { studies } = response;
		return studies as IComparisonStudy[];
	}
}
