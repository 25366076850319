import store from '@/store';
import globals from '@/utils/globals';
import ImpressionTracker from '@/utils/impression-tracker';
import { OnMounted } from '@studyportals/vue-platform';
import { computed, ref, Ref } from 'vue';
import application from '@/main';
import { OpenInternalComparisonCommand } from 'interfaces';

export default class Widget implements OnMounted {
	public WidgetContainer: Ref<HTMLElement | null> = ref(null);

	public canShowComparison = computed((): boolean =>
		store.getters.canShowComparison()
	);

	public isSelected = computed((): boolean => {
		return store.getters.studyIds().some((study) => study === this.studyId);
	});

	public widgetLabel = computed((): string => {
		if (this.canShowComparison.value && this.isSelected.value) {
			return 'Compare now';
		}
		return this.isSelected.value ? 'Added to compare' : 'Add to compare';
	});

	private impressionTracker?: ImpressionTracker;

	constructor(private studyId: number) {}

	public mounted(): void {
		this.impressionTracker = new ImpressionTracker(
			this.WidgetContainer.value,
			this.studyId
		);
		this.impressionTracker?.processElement();
	}

	public toggleStudySelected(event: Event): void {
		event.preventDefault();
		event.stopPropagation();

		if (this.isSelected.value) {
			store.actions.remove(this.studyId);
			return;
		}

		store.actions.addStudyId(this.studyId);

		globals.datalakeTracker.trackClick(this.studyId);
	}

	public labelClicked(event: Event): void {
		event.preventDefault();
		event.stopPropagation();

		if (this.isSelected.value && this.canShowComparison.value) {
			application.openInternalComparison(new OpenInternalComparisonCommand());
		} else {
			this.toggleStudySelected(event);
		}
	}
}
