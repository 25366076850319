import { reactive } from 'vue';

import { IState } from '../interfaces/store.interfaces';

const state: IState = reactive({
	studies: [],
	studyIds: [],
	initialized: false,
	error: false,
});

export default state;
