import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65da9b38"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "WidgetContainer",
  ref: "WidgetContainer"
}
const _hoisted_2 = { class: "WidgetCheckbox js-Compare-Widget" }
const _hoisted_3 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.labelClicked($event))),
        class: _normalizeClass([_ctx.canShowComparison && _ctx.isSelected ? 'WidgetGreen' : '', "WidgetLabel"])
      }, _toDisplayString(_ctx.widgetLabel), 3 /* TEXT, CLASS */),
      _createElementVNode("input", {
        type: "checkbox",
        checked: _ctx.isSelected,
        ref: "checkbox"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("span", {
        class: _normalizeClass(["Checkmark", { Selected: _ctx.isSelected }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleStudySelected($event)))
      }, null, 2 /* CLASS */)
    ])
  ], 512 /* NEED_PATCH */))
}