import { Tracker, Product, Action } from '@studyportals/datalake-event-tracker';
import { ITrackingData } from '@studyportals/datalake-event-tracker/dist/src/interfaces/tracking-data.interface';

export default class DatalakeTracker {
	private tracker: Tracker;

	constructor() {
		this.tracker = new Tracker(Product.COMPARISON_TOOL);
	}

	public trackImpression(studyId: number): void {
		const data: ITrackingData = {
			action: Action.IMPRESSION,
			label: 'widget@search_card',
			property: String(studyId),
		};

		try {
			this.tracker.trackStructuredEvent(data);
		} catch (error) {
			console.warn('Error tracking impression', error);
		}
	}

	public trackClick(studyId: number): void {
		const data: ITrackingData = {
			action: Action.CLICK,
			label: 'widget@search_card',
			property: String(studyId),
		};

		try {
			this.tracker.trackStructuredEvent(data);
		} catch (error) {
			console.warn('Error tracking click', error);
		}
	}
}
