import store from '.';
import { StudyDataRepository } from '../domain/repositories/study-data-repository';
import { IActions } from '../interfaces/store.interfaces';
import mutations from './mutations';

const actions: IActions = {
	initializeData: async (studies?: number[]): Promise<void> => {
		let studyIds = studies;

		if (!studyIds || !studyIds.length) {
			studyIds = store.getters.studyIds();
		}

		const studyData = await new StudyDataRepository().get(studyIds);

		if (studyData.length === 0) {
			mutations.setErrorFlag();
		}

		mutations.setStudies(studyData);
		mutations.setInitializedFlag();
	},
	remove: (studyId: number): void => {
		mutations.removeStudyId(studyId);
		mutations.removeStudy(studyId);
	},
	addStudyId: (studyId: number): void => {
		mutations.addStudyId(studyId);
	},
};

export default actions;
