/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApp, h } from 'vue';
import Widget from '../presentation/Widget/widget.vue';
import { defineAsyncComponent } from 'vue';
import {
	RenderWidgetsCommand,
	OpenInternalComparisonCommand,
	OpenExternalComparisonCommand,
} from 'interfaces';
import store from '@/store';
import { IComparisonToolApplication } from 'interfaces';

export class ComparisonToolApplication implements IComparisonToolApplication {
	public openInternalComparison(command: OpenInternalComparisonCommand): void {
		if (!store.getters.canShowComparison()) {
			throw new Error('No studies to compare');
		}

		document.dispatchEvent(
			new CustomEvent('load_comparison_drawer_independent')
		);
		this.openComparison(store.getters.studyIds());
	}

	public openExternalComparison(command: OpenExternalComparisonCommand): void {
		if (!Array.isArray(command.ids) || command.ids.length === 0) {
			throw new Error('Invalid ids provided');
		}

		document.dispatchEvent(new CustomEvent('load_comparison_drawer'));
		this.openComparison(command.ids);
	}

	public renderWidgets(command: RenderWidgetsCommand): void {
		const nodes: NodeListOf<Element> =
			document.querySelectorAll('.ComparisonWidget');

		if (nodes.length < 1) {
			console.warn('No valid comparison placeholders found');
			return;
		}

		nodes.forEach((node) => {
			const studyId = parseInt(
				node.getAttribute('data-study-id') as string,
				10
			);
			const widgetLocation = node.getAttribute(
				'data-widget-location'
			) as string;

			if (!studyId) {
				console.warn('Invalid study id set');
				return;
			}

			const widget = createApp({
				render: () => {
					return h(Widget, {
						studyId,
						widgetLocation,
					});
				},
			});

			widget.mount(node);
		});
	}

	private openComparison(ids: number[]): void {
		const appComponent = defineAsyncComponent(
			() => import('../presentation/Comparison/app.vue')
		);

		const app = createApp({
			render: () => {
				return h(appComponent, {
					studyIds: ids,
				});
			},
		});

		app.mount('#ComparisonToolPlaceholder');
	}
}
